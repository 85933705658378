<template>
  <div class="auth-container">
    <div class="auth-hero">
      <img :src="heroImageSrc" class="auth-hero-image" />
    </div>
    <div class="auth-content">
      <div class="auth-logo">
        <img :src="logoSrc" class="logo" />
        <span class="logo-badge">
          <small>{{ $t('for') }}&nbsp;</small>
          <strong>{{ $t('business') }}</strong>
        </span>
      </div>
      <p>
        {{ $t('Welcome to') }}<strong> toddl.co</strong><br />
        {{ $t('the smart software for exceptional educators!') }}
      </p>

      <div v-if="hasVerifiedEmail" class="login">
        <validation-observer ref="loginForm" #default="{ invalid }">
          <b-form @submit.prevent="login">
            <b-form-group :label="$t('Email')" label-for="login-email">
              <validation-provider
                #default="{ errors }"
                :name="$t('Email')"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="login-email"
                  v-model="email"
                  :state="errors.length > 0 ? false : null"
                  name="login-email"
                  placeholder="you@gmail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <div class="password-label">
                <label for="login-password">{{ $t('Password') }}</label>
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>{{ $t('Forgot Password?') }}</small>
                </b-link>
              </div>
              <validation-provider
                #default="{ errors }"
                :name="$t('Password')"
                vid="password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="login-password"
                    v-model="password"
                    :state="errors.length > 0 ? false : null"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    name="login-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group v-if="isAdmin" :label="$t('Login as')" label-for="loginAs">
              <validation-provider
                #default="{ errors }"
                :name="$t('Login as')"
                vid="loginAs"
                rules="email"
              >
                <b-form-input
                  id="login-email"
                  v-model="loginAs"
                  :state="errors.length > 0 ? false : null"
                  name="loginAs"
                  placeholder="client@gmail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="ml-auto mr-auto mt-3 w-50"
              block
              :disabled="invalid || isLoading"
            >
              {{ $t('Sign in') }}
            </b-button>
          </b-form>
        </validation-observer>
        <div class="signup-link">
          <span>{{ $t("Don't have an account?") }} </span>
          <b-link :to="{ name: 'auth-register' }">
            <span style="text-decoration: underline">{{ $t('Sign up') }}</span>
          </b-link>
        </div>
        <p class="parent-login">
          <span>{{ $t('Are you a family looking to book activities?') }}</span>
          <a href="https://toddl.co/login" class="btn btn-secondary">{{
            $t('Login as a parent')
          }}</a>
        </p>
      </div>
      <div v-else>
        <p>
          {{ $t('Please check your email to verify your account') }}
        </p>
        <b-button
          variant="primary"
          block
          class="ml-auto mr-auto mt-3 w-50"
          :disabled="timer !== 0 || isLoading"
          @click.prevent="resendEmail"
        >
          <b-spinner v-if="isLoading" small />
          {{ $t('Resend email') }} {{ timer > 0 ? timer : '' }}
        </b-button>
        <p
          class="text-center text-primary mt-1 text-underline cursor-pointer"
          @click="hasVerifiedEmail = true"
        >
          {{ $t('Back to login') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BForm,
  BButton,
  VBTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { required, email } from '@/libs/validations'
import { togglePasswordVisibility } from '@/mixins/togglePasswordVisibility'

import heroImageSrc from '@/assets/images/provider/login-background.jpg'
import logoSrc from '@/assets/images/logo/toddl.co_logo white.svg'
import AuthService from '@/services/AuthService'
import apiService from '@/services/shared/http/apiService'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BSpinner,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      AuthService: new AuthService(),
      api: new apiService(),
      password: '',
      email: '',
      isAdmin: false,
      loginAs: '',
      isLoading: false,
      hasVerifiedEmail: true,
      timer: 0,
      heroImageSrc,
      logoSrc,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    email: {
      handler(value) {
        if (value.endsWith('@toddl.co')) {
          this.isAdmin = true
        } else {
          this.isAdmin = false
        }
      },
    },
    timer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timer--
          }, 1000)
        }
      },
    },
  },
  mounted() {
    if (!this.$route.query?.lang) this.$route.query.lang = 'es'
    if (this.$route.query?.lang === 'en' || this.$route.query?.lang === 'es') {
      localStorage.setItem('locale', this.$route.query.lang)
      this.$store.commit('appConfig/SET_LANG', this.$route.query.lang)
      this.$i18n.locale = this.$route.query.lang
    }
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.isLoading = true
          let payload = {
            email: this.email,
            password: this.password,
          }
          if (this.isAdmin) {
            payload.login_as = this.loginAs
          }
          this.AuthService.login(payload)
            .then(res => {
              this.isLoading = false

              const { location, types, activity, access_token, ...user } = res.user

              this.$store.commit('auth/login', {
                accessToken: access_token,
                user: user,
              })

              if (this.isAdmin && this.loginAs) {
                this.$posthog.identify('IMPERSONATED_MASTER_USER')
                localStorage.setItem('isImpersonatingUser', true)
              } else {
                this.$posthog.identify(user.id.toString(), {
                  email: user.email,
                  name: user.name,
                  provider: user.business_name,
                  plan: user.plan,
                  period: user.payment_frequency,
                  trial_ends_at: user.trial_ends_at,
                })
                this.$posthog.capture('Login', {
                  email: this.email,
                })
              }

              this.$router.replace({ name: 'overview' })
            })
            .catch(err => {
              console.error(err)
              if (err.errors[0] && err.status_code === 400) {
                this.hasVerifiedEmail = false
                this.resendEmail()
                return
              }
              this.isLoading = false
            })
        }
      })
    },
    resendEmail() {
      this.isLoading = true
      this.api.POST(`resend-email-verification?email=${this.email}`).then(
        () => {
          this.isLoading = false
          this.timer = 60
        },
        err => {
          this.isLoading = false
          console.error(err)
        }
      )
    },
  },
}
</script>

<style scoped>
.auth-container {
  display: grid;
  gap: 0;
  grid-template-columns: minmax(0, 0%) minmax(0, 1fr);
  height: 100vh;
  background-color: #fff;
}

.auth-hero {
  position: relative;
  height: 100%;
}

.auth-hero-image {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.auth-content {
  padding: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #0e1a1a;
}

.auth-logo {
  display: flex;
  flex-direction: row;
  height: fit-content;
  gap: 12px;
  margin: 0 auto;
  margin-bottom: 32px;
}
.logo {
  width: 100%;
}

.logo-badge {
  font-size: 26px;
  display: inline-block;
  color: #4b64a5;
  margin-bottom: 8px;
}

.logo-badge strong {
  font-weight: 700;
}

.password-label {
  display: flex;
  justify-content: space-between;
}

.login {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.signup-link {
  padding: 12px;
  text-align: center;
}

.parent-login {
  border-top: 1px solid #eee;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .auth-container {
    grid-template-columns: minmax(0, 66%) minmax(0, 1fr);
  }
}
</style>
