var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-container"},[_c('div',{staticClass:"auth-hero"},[_c('img',{staticClass:"auth-hero-image",attrs:{"src":_vm.heroImageSrc}})]),_c('div',{staticClass:"auth-content"},[_c('div',{staticClass:"auth-logo"},[_c('img',{staticClass:"logo",attrs:{"src":_vm.logoSrc}}),_c('span',{staticClass:"logo-badge"},[_c('small',[_vm._v(_vm._s(_vm.$t('for'))+" ")]),_c('strong',[_vm._v(_vm._s(_vm.$t('business')))])])]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('Welcome to'))),_c('strong',[_vm._v(" toddl.co")]),_c('br'),_vm._v(" "+_vm._s(_vm.$t('the smart software for exceptional educators!'))+" ")]),(_vm.hasVerifiedEmail)?_c('div',{staticClass:"login"},[_c('validation-observer',{ref:"loginForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"you@gmail.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"password-label"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v(_vm._s(_vm.$t('Password')))]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password' }}},[_c('small',[_vm._v(_vm._s(_vm.$t('Forgot Password?')))])])],1),_c('validation-provider',{attrs:{"name":_vm.$t('Password'),"vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.isAdmin)?_c('b-form-group',{attrs:{"label":_vm.$t('Login as'),"label-for":"loginAs"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Login as'),"vid":"loginAs","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login-email","state":errors.length > 0 ? false : null,"name":"loginAs","placeholder":"client@gmail.com"},model:{value:(_vm.loginAs),callback:function ($$v) {_vm.loginAs=$$v},expression:"loginAs"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('b-button',{staticClass:"ml-auto mr-auto mt-3 w-50",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(_vm.$t('Sign in'))+" ")])],1)]}}],null,false,2237593114)}),_c('div',{staticClass:"signup-link"},[_c('span',[_vm._v(_vm._s(_vm.$t("Don't have an account?"))+" ")]),_c('b-link',{attrs:{"to":{ name: 'auth-register' }}},[_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v(_vm._s(_vm.$t('Sign up')))])])],1),_c('p',{staticClass:"parent-login"},[_c('span',[_vm._v(_vm._s(_vm.$t('Are you a family looking to book activities?')))]),_c('a',{staticClass:"btn btn-secondary",attrs:{"href":"https://toddl.co/login"}},[_vm._v(_vm._s(_vm.$t('Login as a parent')))])])],1):_c('div',[_c('p',[_vm._v(" "+_vm._s(_vm.$t('Please check your email to verify your account'))+" ")]),_c('b-button',{staticClass:"ml-auto mr-auto mt-3 w-50",attrs:{"variant":"primary","block":"","disabled":_vm.timer !== 0 || _vm.isLoading},on:{"click":function($event){$event.preventDefault();return _vm.resendEmail.apply(null, arguments)}}},[(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('Resend email'))+" "+_vm._s(_vm.timer > 0 ? _vm.timer : '')+" ")],1),_c('p',{staticClass:"text-center text-primary mt-1 text-underline cursor-pointer",on:{"click":function($event){_vm.hasVerifiedEmail = true}}},[_vm._v(" "+_vm._s(_vm.$t('Back to login'))+" ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }